.up{
    position:fixed;
    right: 10px;
    bottom: 50px;
    background-color: #B22222;
    width: 50px;
    height: 50px;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items: center;
    color: #fff;
    cursor: pointer;
}
