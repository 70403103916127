@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC&display=swap');
.home {
    background-image: linear-gradient(90deg, rgb(159 159 159 / 46%) 0%, rgb(205 205 205 / 51%) 100%), url(../../img/bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
     background-position:cover;
    height: 500px;
    color: #fff;
}
.swiper{
    height: 100% !important;

}
.slide {
    height: 500px;
    display: flex;
    justify-content:center;
    align-items:center;
}
 
.slide_title{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    font-family: 'Playfair Display SC', serif;
}
.slide_img{
    height: 400px;
    width: 400px;
    border-radius: 20%;
}

@media screen and (min-width: 320px) and (max-width: 500px) {
    .slide_img{
        height: 200px;
        width: 200px;
        border-radius: 10px;
    }
    .slide_title{
       font-size: 12px;
    }
  }