@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto);
.up{
    position:fixed;
    right: 10px;
    bottom: 50px;
    background-color: #B22222;
    width: 50px;
    height: 50px;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items: center;
    color: #fff;
    cursor: pointer;
}

.Contact_contact__xj64B {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%), url(/static/media/bg.cde2b10f.png);
    color: #fff;
}

.Contact_contact_info__FqZhp,
.Contact_contact_followus__arFeG {
    margin-top: 40px;
}

.Contact_contact_followus__arFeG {
    -webkit-margin-start: 50px;
            margin-inline-start: 50px;
}
.Contact_info_title__1683G {
    border-bottom: 1px solid #fff;
    padding-bottom: 20px;
    font-family: 'Playfair Display SC', serif;
}
.Contact_contact_followus__arFeG h3{
font-family: 'Playfair Display SC', serif;
}
.Contact_contact_followus__arFeG ul {
    list-style: none;
    -webkit-margin-start: 20px;
            margin-inline-start: 20px;
    margin-top: 23px;

}

.Contact_contact_followus__arFeG ul li {
    margin-bottom: 15px;
}
.home_home__uuQfk {
    background-image: linear-gradient(90deg, rgb(159 159 159 / 46%) 0%, rgb(205 205 205 / 51%) 100%), url(/static/media/bg.e8cd4546.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
     background-position:cover;
    height: 500px;
    color: #fff;
}
.home_swiper__3eJEj{
    height: 100% !important;

}
.home_slide__1RZcj {
    height: 500px;
    display: flex;
    justify-content:center;
    align-items:center;
}
 
.home_slide_title__2phv_{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    font-family: 'Playfair Display SC', serif;
}
.home_slide_img__MbfKV{
    height: 400px;
    width: 400px;
    border-radius: 20%;
}

@media screen and (min-width: 320px) and (max-width: 500px) {
    .home_slide_img__MbfKV{
        height: 200px;
        width: 200px;
        border-radius: 10px;
    }
    .home_slide_title__2phv_{
       font-size: 12px;
    }
  }
.services {
  padding: 50px;
  background-color: #000000;
}

.Sectors {
  text-align: center;
  color: #fff;
  margin: 40px;

}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
  border-radius: 20px;
  margin-bottom: 20px;

}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  border-radius: 20px;


}

.flip-card:focus {
  outline: 0;
}

.flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;

}

.flip-card-front {
  background: cover;
  height: 300px;
  width: 300px;
  color: black;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

}

.one {
  background-image: url(/static/media/small.1b54c812.jpg);
}

.secnd {
  background-image: url(/static/media/small_2.c825752f.jpg);
}

.theard {
  background-image: url(/static/media/small_3.5a4df953.jpg);

}

.flip-card-back {
  background: linear-gradient(to right, #c8c8c8, #000000);
  color: white;
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

}

h3 {
  font-size: 20px;
  font-family: Verdana, sans-serif;
  font-weight: bold;
  color: #fff;
}

@media screen and (min-width: 320px) and (max-width: 500px) {
  .flip-card-front {
    height: 240px;
    width: 240px;
  }
  .flip-card {
     width: 240px;
    height: 240px;
    
  
  }
}
body {
  font-family: 'Roboto', sans-serif;
  background: #fff; /* fallback for old browsers */
}
* {
  margin: 0;
  padding: 0;
}
nav i {
  margin-right: 10px;
}
.navbar-logo {
   color: #fff;
  pointer-events: none;
}
.logo{
  border-radius: 50px;
}
.logo_name{
  -webkit-margin-start: 10px;
          margin-inline-start: 10px;
  font-family: 'Playfair Display SC', serif;
  letter-spacing: 5px;
  font-size:12px
}

.navbar-mainbg {
  background-color: #000000;
  padding: 0px;
}
#navbarSupportedContent {
  overflow: hidden;
  position: relative;
}
#navbarSupportedContent ul {
  padding: 0px;
  margin: 0px;
}
#navbarSupportedContent ul li a i {
  margin-right: 10px;
}
#navbarSupportedContent li {
  list-style-type: none;
  float: left;
}
#navbarSupportedContent ul li a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  font-size: 15px;
  display: block;
  padding: 20px 20px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
  cursor: pointer;
}
#navbarSupportedContent > ul > li.active > a {
  color: #000000;
  background-color: transparent;
  transition: all 0.7s;
}
#navbarSupportedContent a:not(:only-child):after {
  content: '\f105';
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 14px;
  font-family: 'Font Awesome 5 Free';
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  transition: 0.5s;
}
#navbarSupportedContent .active > a:not(:only-child):after {
  transform: rotate(90deg);
}

.hori-selector {
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 0px;
  left: 0px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: 10px;
}
.hori-selector .right,
.hori-selector .left {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #fff;
  bottom: 10px;
}
.hori-selector .right {
  right: -25px;
}
.hori-selector .left {
  left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000000;
}
.hori-selector .right:before {
  bottom: 0;
  right: -25px;
}
.hori-selector .left:before {
  bottom: 0;
  left: -25px;
}
@media screen and (min-width: 320px) and (max-width: 500px) {
  .logo_name{
    letter-spacing: 0px;
  }
}
@media (max-width: 991px) {
  #navbarSupportedContent ul li a {
    padding: 12px 30px;
  }
  .hori-selector {
    margin-top: 0px;
    margin-left: 10px;
    border-radius: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .hori-selector .left,
  .hori-selector .right {
    right: 10px;
  }
  .hori-selector .left {
    top: -25px;
    left: auto;
  }
  .hori-selector .right {
    bottom: -25px;
  }
  .hori-selector .left:before {
    left: -25px;
    top: -25px;
  }
  .hori-selector .right:before {
    bottom: -25px;
    left: -25px;
  }
}

 
  
.header{
    display:flex;
    justify-content:space-around;
    align-items:center;
    padding: 3px;
    background-color: #B22222;
}
.header_title{
    font-family: 'Playfair Display SC', serif;
    font-weight: bold;
    letter-spacing: 3px;
    color: #fff;
    font-size: 20px;
    /* color: #e4ca8f; */
}
 
  .button {
	float: left;
	width: 30px;
	height: 30px;
	cursor: pointer;
	background: #fff;
	overflow: hidden;
	border-radius: 50px;
	transition: all 0.3s ease-in-out;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    -webkit-margin-end: 5px;
            margin-inline-end: 5px;

}

.button span {
	font-size: 12px;
	font-weight: 500;
	line-height: 30px;
	margin-left: 10px;
}

.button:hover {
	width: 200px;
}

.button:nth-child(1):hover .icon {
	background: #e1306c;
}

.button:nth-child(2):hover .icon {
	background: #4267b2;
}

.button:nth-child(3):hover .icon {
	background: #1da1f2;
}

.button:nth-child(4):hover .icon {
	background: #0e76a8;
}

.button:nth-child(5):hover .icon {
	background: #ff0000;
}

.button:nth-child(6):hover .icon {
	background: #333;
}

.button:nth-child(1) span {
	color: #e1306c;
}

.button:nth-child(2) span {
	color: #4267b2;
}

.button:nth-child(3) span {
	color: #1da1f2;
}

.button:nth-child(4) span {
	color: #0e76a8;
}

.button:nth-child(5) span {
	color: #ff0000;
}

.button:nth-child(6) span {
	color: #333;
}

.button .icon {
	width: 30px;
	height: 30px;
	text-align: center;
	border-radius: 50px;
	display: inline-block;
	transition: all 0.3s ease-in-out;
}

.button .icon i {
	font-size: 12px;
	line-height: 30px;
	transition: all 0.3s ease-in-out;
}

.button:hover i {
	color: #fff;
}
 

